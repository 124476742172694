<template>
  <div>
    <NavigationBar
      titel="Details"
      :actions="actions"
      showBackBtn
    ></NavigationBar>
    <section v-if="helpers.dataIsLoading">
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-skeleton-loader type="sentences"></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <section v-else>
      <template>
        <v-container fluid>
          <v-row class="match-height">
            <v-col cols="12">
              <v-card>
                <v-card-title class="subheading">
                  {{ batchData.title }}
                  <v-spacer></v-spacer>
                  <payroll-status-chip
                    :status="batchData.status"
                  ></payroll-status-chip>
                </v-card-title>
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12" sm="6" md="3">
                      <v-card outlined>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>{{
                              timestampConverter(
                                batchData.period.start.timestamp
                              )
                            }}</v-list-item-title>
                            <v-list-item-subtitle
                              >Beginn Abrechnungsperiode</v-list-item-subtitle
                            >
                          </v-list-item-content>
                        </v-list-item>
                      </v-card>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <v-card outlined>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>{{
                              timestampConverter(batchData.period.end.timestamp)
                            }}</v-list-item-title>
                            <v-list-item-subtitle
                              >Ende Abrechnungsperiode</v-list-item-subtitle
                            >
                          </v-list-item-content>
                        </v-list-item>
                      </v-card>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <v-card outlined>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>{{
                              batchData.persons.length
                            }}</v-list-item-title>
                            <v-list-item-subtitle
                              >Anzahl Abrechnungszettel</v-list-item-subtitle
                            >
                          </v-list-item-content>
                        </v-list-item>
                      </v-card>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <v-card outlined>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>{{
                              getPayrollTypeText(batchData.type)
                            }}</v-list-item-title>
                            <v-list-item-subtitle
                              >Abrechnungsvariante</v-list-item-subtitle
                            >
                          </v-list-item-content>
                        </v-list-item>
                      </v-card>
                    </v-col>
                    <v-col cols="12">
                      <v-card outlined>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-subtitle
                              >Verwendete Abrechnungsgruppen
                              <small
                                >({{
                                  batchData.payrollGroups.data.length
                                }})</small
                              >
                            </v-list-item-subtitle>
                            <v-list-item-title class="mt-1"
                              ><v-chip
                                v-for="payrollGroup in batchData.payrollGroups
                                  .data"
                                :key="payrollGroup.id"
                                small
                                class="mr-1 font-weight-medium"
                                outlined
                                label
                                >{{ payrollGroup.title }}</v-chip
                              ></v-list-item-title
                            >
                          </v-list-item-content>
                        </v-list-item>
                      </v-card>
                    </v-col>
                    <v-col>
                      <v-card outlined>
                        <v-list-item
                          @click="showPersonTable = !showPersonTable"
                        >
                          <v-list-item-content>
                            <v-list-item-subtitle
                              >Personen in Abrechnungsstapel
                              <small
                                >({{ batchData.persons.length }})</small
                              ></v-list-item-subtitle
                            >
                          </v-list-item-content>
                          <v-list-item-action
                            ><v-btn depressed icon small>
                              <v-icon
                                >{{
                                  showPersonTable
                                    ? "mdi-chevron-up"
                                    : "mdi-chevron-down"
                                }}
                              </v-icon>
                            </v-btn>
                          </v-list-item-action>
                        </v-list-item>
                        <v-expand-transition>
                          <div v-show="showPersonTable">
                            <v-divider></v-divider>
                            <v-data-table
                              :headers="personTableHeaders"
                              :items="batchData.persons"
                              multi-sort
                              group-by="user.division.title"
                              item-key="user.uid"
                              sort-by="user.displayName"
                              :sort-desc="false"
                              dense
                              :items-per-page="25"
                              :footer-props="{
                                'items-per-page-options': [25, 50, 100, -1],
                              }"
                              :mobile-breakpoint="0"
                            >
                              <template
                                v-slot:[`group.header`]="{
                                  items,
                                  isOpen,
                                  toggle,
                                }"
                              >
                                <th
                                  :colspan="batchData.persons.length + 1"
                                  class="group-header-th"
                                >
                                  <v-icon @click="toggle" class="mr-2"
                                    >{{
                                      isOpen
                                        ? "mdi-chevron-up"
                                        : "mdi-chevron-down"
                                    }}
                                  </v-icon>
                                  <span>
                                    {{ items[0].user.division.title }}
                                  </span>
                                </th>
                              </template>
                            </v-data-table>
                          </div>
                        </v-expand-transition>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="6" sm="6">
              <v-card>
                <v-card-title class="body-2 v-card__subtitle"
                  ><v-icon left small class="mr-2">mdi-timeline-check</v-icon
                  >Bearbeitungsstand
                </v-card-title>
                <v-divider></v-divider>
                <v-list nav dense>
                  <v-list-item
                    link
                    three-line
                    :disabled="batchData.status !== 'warningsToBeClosed'"
                  >
                    <v-list-item-content>
                      <v-list-item-title>
                        <div
                          v-if="batchData.status === 'warningsToBeClosed'"
                          class="caption primary--text"
                        >
                          AKTUELLER SCHRITT
                        </div>
                        <span class="subtitle-2"
                          >Warnungen prüfen und schließen</span
                        >
                      </v-list-item-title>
                      <v-list-item-subtitle class="font-weight-regular"
                        >Für mögliche Fehlerquellen werden Warnungen angezeigt,
                        die durch vollständige Abarbeitung gelöst/geschlossen
                        werden können.
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-avatar
                        v-if="batchData.status !== 'warningsToBeClosed'"
                        color="green lighten-5"
                        size="28"
                      >
                        <v-icon color="green darken-5" small>
                          mdi-check
                        </v-icon></v-avatar
                      >
                      <v-btn
                        v-else
                        small
                        class="mt-n1"
                        outlined
                        text
                        :loading="helpers.closeWarningsAction.isLoading"
                        @click="closeWarnings()"
                        ><v-icon left small>mdi-play</v-icon>Abschließen</v-btn
                      >
                    </v-list-item-action>
                  </v-list-item>
                  <v-alert
                    v-if="helpers.closeWarningsAction.errorMessage"
                    type="error"
                    dense
                    text
                    >{{ helpers.closeWarningsAction.errorMessage }}</v-alert
                  >
                  <v-list-item
                    link
                    three-line
                    :disabled="batchData.status !== 'payslipsToBeGenerated'"
                  >
                    <v-list-item-content>
                      <v-list-item-title
                        ><div
                          v-if="batchData.status === 'payslipsToBeGenerated'"
                          class="caption primary--text"
                        >
                          AKTUELLER SCHRITT
                        </div>
                        Abrechnungszettel generieren
                      </v-list-item-title>
                      <v-list-item-subtitle
                        >Genehmigte Stunden-, Lehrgangsmeldungen, Auslagen und
                        sonstige Einträge werden in Abrechnungszetteln
                        zusammengeführt.</v-list-item-subtitle
                      >
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-avatar
                        v-if="
                          batchData.status === 'paymentToBeInstructed' ||
                          batchData.status === 'waitingOnPayment' ||
                          batchData.status === 'paid'
                        "
                        color="green lighten-5"
                        size="28"
                      >
                        <v-icon color="green darken-5" small>
                          mdi-check
                        </v-icon></v-avatar
                      >
                      <v-btn
                        v-else-if="batchData.status === 'payslipsToBeGenerated'"
                        small
                        class="mt-n1"
                        outlined
                        text
                        :loading="helpers.generatePayslipsAction.isLoading"
                        @click="generatePayslips()"
                        ><v-icon left small>mdi-play</v-icon>Ausführen</v-btn
                      >
                    </v-list-item-action>
                  </v-list-item>
                  <v-alert
                    v-if="helpers.generatePayslipsAction.errorMessage"
                    type="error"
                    dense
                    text
                    >{{ helpers.generatePayslipsAction.errorMessage }}</v-alert
                  >
                  <v-list-item
                    link
                    three-line
                    :disabled="batchData.status !== 'paymentToBeInstructed'"
                  >
                    <v-list-item-content>
                      <v-list-item-title>
                        <div
                          v-if="batchData.status === 'paymentToBeInstructed'"
                          class="caption primary--text"
                        >
                          AKTUELLER SCHRITT
                        </div>
                        Auszahlung anweisen
                      </v-list-item-title>
                      <v-list-item-subtitle
                        >Die Anweisung der Auszahlung ist zu erfassen. Es kann
                        zudem nun ein Export des Abrechnungsstapels durchgeführt
                        werden.</v-list-item-subtitle
                      >
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-avatar
                        v-if="
                          batchData.status === 'waitingOnPayment' ||
                          batchData.status === 'paid'
                        "
                        color="green lighten-5"
                        size="28"
                      >
                        <v-icon color="green darken-5" small>
                          mdi-check
                        </v-icon></v-avatar
                      >
                      <v-btn
                        v-else-if="batchData.status === 'paymentToBeInstructed'"
                        small
                        class="mt-n1"
                        outlined
                        text
                        :disabled="batchData.status !== 'paymentToBeInstructed'"
                        @click="toggleInstructPaymentDialog()"
                        ><v-icon left small>mdi-play</v-icon>Ausführen</v-btn
                      >
                    </v-list-item-action>
                  </v-list-item>
                  <v-alert
                    v-if="helpers.requestPaymentAction.errorMessage"
                    type="error"
                    dense
                    text
                    >{{ helpers.requestPaymentAction.errorMessage }}</v-alert
                  >
                  <v-list-item
                    link
                    three-line
                    :disabled="batchData.status !== 'waitingOnPayment'"
                  >
                    <v-list-item-content>
                      <v-list-item-title
                        ><div
                          v-if="batchData.status === 'waitingOnPayment'"
                          class="caption primary--text"
                        >
                          AKTUELLER SCHRITT
                        </div>
                        Zahlungsstatus erfassen</v-list-item-title
                      >
                      <v-list-item-subtitle
                        >Nach durchgeführter Zahlung wird das Auszahlungsdatum
                        erfasst.</v-list-item-subtitle
                      >
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-avatar
                        v-if="batchData.status === 'paid'"
                        color="green lighten-5"
                        size="28"
                      >
                        <v-icon color="green darken-5" small>
                          mdi-check
                        </v-icon></v-avatar
                      >
                      <v-btn
                        v-else-if="batchData.status === 'waitingOnPayment'"
                        small
                        class="mt-n1"
                        outlined
                        :disabled="batchData.status !== 'waitingOnPayment'"
                        text
                        @click="toggleRecordPaymentDialog()"
                        ><v-icon left small>mdi-play</v-icon>Ausführen</v-btn
                      >
                    </v-list-item-action>
                  </v-list-item>
                  <v-alert
                    v-if="helpers.updatePaymentStatusAction.errorMessage"
                    type="error"
                    dense
                    text
                    >{{
                      helpers.updatePaymentStatusAction.errorMessage
                    }}</v-alert
                  >
                </v-list>
              </v-card>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-card>
                <v-card-title class="body-2 v-card__subtitle font-weight-medium"
                  ><v-icon left small class="mr-2">mdi-alert</v-icon>Warnungen
                </v-card-title>
                <v-divider></v-divider>
                <v-simple-table>
                  <template v-slot:default>
                    <tbody>
                      <tr>
                        <td class="font-weight-medium">
                          {{ warningCounts.dutyReports.toBeReviewed }}
                        </td>
                        <td>
                          <div class="d-flex flex-column">
                            <span
                              class="d-block font-weight-semibold text--primary text-truncate"
                              >Zu prüfende Dienstberichte in
                              Abrechnungsperiode</span
                            >

                            <small class="grey--text"
                              >{{ warningCounts.dutyReports.approved }} von
                              {{ warningCounts.dutyReports.total }} bereits
                              geprüft</small
                            >
                          </div>
                        </td>
                        <td>
                          <v-btn
                            icon
                            small
                            color="primary"
                            :disabled="
                              warningCounts.totalWarningDutyReports === 0
                            "
                            @click="toggleViewMode('dutyReports')"
                            ><v-icon small>mdi-arrow-right</v-icon></v-btn
                          >
                        </td>
                      </tr>
                      <tr>
                        <td class="font-weight-medium">
                          {{ warningCounts.timeSheets.toBeReviewed }}
                        </td>
                        <td>
                          <div class="d-flex flex-column">
                            <span
                              class="d-block font-weight-semibold text--primary text-truncate"
                            ></span
                            >Zu prüfende Stundenmeldungen in Abrechnungsperiode
                            <small class="grey--text"
                              >{{ warningCounts.timeSheets.approved }} von
                              {{ warningCounts.timeSheets.total }} bereits
                              geprüft</small
                            >
                          </div>
                        </td>
                        <td>
                          <v-btn
                            icon
                            small
                            color="primary"
                            @click="toggleViewMode('timeSheets')"
                            ><v-icon small>mdi-arrow-right</v-icon></v-btn
                          >
                        </td>
                      </tr>
                      <tr class="grey--text">
                        <td class="font-weight-medium">N/A</td>
                        <td>
                          Zu prüfende Lehrgangsmeldungen in Abrechnungsperiode
                        </td>
                        <td>
                          <v-btn icon small color="primary" disabled
                            ><v-icon small>mdi-arrow-right</v-icon></v-btn
                          >
                        </td>
                      </tr>
                      <tr class="grey--text">
                        <td class="font-weight-medium">N/A</td>
                        <td>Zu prüfende Auslagen in Abrechnungsperiode</td>
                        <td>
                          <v-btn icon small color="primary" disabled
                            ><v-icon small>mdi-arrow-right</v-icon></v-btn
                          >
                        </td>
                      </tr>

                      <tr class="grey--text">
                        <td class="font-weight-medium">N/A</td>
                        <td>Personen ohne Bankverbindung</td>
                        <td>
                          <v-btn icon small color="primary" disabled
                            ><v-icon small>mdi-arrow-right</v-icon></v-btn
                          >
                        </td>
                      </tr>
                      <tr class="grey--text">
                        <td class="font-weight-medium">N/A</td>
                        <td>Personen mit unvollständigen Abrechnungsdaten</td>
                        <td>
                          <v-btn icon small color="primary" disabled
                            ><v-icon small>mdi-arrow-right</v-icon></v-btn
                          >
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card>
            </v-col>
          </v-row>
          <v-row
            v-if="
              batchData.status === 'paymentToBeInstructed' ||
              batchData.status === 'waitingOnPayment' ||
              batchData.status === 'paid'
            "
          >
            <v-col cols="12" md="8" sm="12">
              <v-data-table
                :headers="headers"
                :items="batchData.persons"
                class="elevation-2"
                show-expand
                :expanded.sync="expanded"
                item-key="user.uid"
                :search="search"
                dense
                no-data-text="Keine Abrechnungszettel vorhanden"
                :mobile-breakpoint="0"
              >
                <template v-slot:top>
                  <v-card flat dense>
                    <v-card-title>
                      <v-row>
                        <v-col cols="4" sm="5" md="5">
                          <v-text-field
                            v-model="search"
                            outlined
                            append-icon="mdi-magnify"
                            label="Suchen"
                            hide-details="auto"
                            clearable
                            dense
                          ></v-text-field>
                        </v-col>
                        <v-col class="d-flex justify-end">
                          <v-btn
                            depressed
                            small
                            class="mr-2"
                            :disabled="
                              batchData.persons.length === expanded.length
                            "
                            @click="expanded = batchData.persons"
                            ><v-icon left>mdi-expand-all</v-icon>Alle
                            ausklappen</v-btn
                          >
                          <v-btn
                            depressed
                            small
                            @click="expanded = []"
                            :disabled="!expanded.length"
                            ><v-icon left>mdi-collapse-all</v-icon>Alle
                            einklappen</v-btn
                          >
                        </v-col>
                      </v-row>
                    </v-card-title>
                  </v-card>
                </template>
                <template v-slot:body.append>
                  <tr class="font-weight-medium v-row-group__header">
                    <td class="text-left" :colspan="2">Gesamt</td>

                    <td class="text-right">
                      <pre
                        >{{ batchData.summary.totalCompensation.amount }} {{
                          batchData.summary.totalCompensation.currency
                        }}</pre
                      >
                    </td>
                  </tr>
                </template>
                <template v-slot:item.user.displayName="{ item }">
                  <div class="d-flex flex-column">
                    <span
                      class="d-block font-weight-semibold text--primary text-truncate"
                      >{{ item.user.displayName }}

                      <v-tooltip bottom v-if="item.payslipId">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            x-small
                            color="primary"
                            v-bind="attrs"
                            v-on="on"
                            :to="{
                              name: 'accounting-payslips-details',
                              params: {
                                organizationId: $route.params.organizationId,
                                itemId: item.payslipId,
                              },
                            }"
                            ><v-icon x-small>mdi-open-in-app</v-icon></v-btn
                          >
                        </template>
                        <span>Abrechnungszettel öffnen</span>
                      </v-tooltip>
                    </span>
                    <small class="grey--text">
                      <span
                        v-if="item.user.dutyPosition.title"
                        class="font-weight-medium"
                        >{{ item.user.dutyPosition.title }},
                      </span>
                      <span
                        >{{ item.payslipSummary.totalItemsCount }}
                        {{
                          item.payslipSummary.totalItemsCount === 1
                            ? "Einträge"
                            : "Eintrag"
                        }}
                        insgesamt
                      </span>
                    </small>
                  </div>
                </template>
                <template
                  v-slot:item.payslipSummary.totalCompensation.amount="{ item }"
                >
                  <pre
                    >{{ item.payslipSummary.totalCompensation.amount }} {{
                      item.payslipSummary.totalCompensation.currency
                    }}</pre
                  >
                </template>
                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length" class="mx-0 px-0">
                    <v-simple-table dense>
                      <template v-slot:default>
                        <thead class="v-row-group__header">
                          <tr>
                            <th class="text-left">Eintrag</th>
                            <th class="text-right">Dauer in H</th>
                            <th class="text-right">Aufwand in EUR</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(lineItem, index) in item.lineItems"
                            :key="index"
                          >
                            <td class="text-left">
                              {{ lineItem.title }}
                              <div style="line-height: 1.4" class="mt-n1">
                                <small class="grey--text">
                                  <span
                                    >{{ lineItem.summary.totalItemsCount }}
                                    {{
                                      lineItem.summary.totalItemsCount === 1
                                        ? "Einträge"
                                        : "Eintrag"
                                    }}
                                  </span>
                                </small>
                              </div>
                            </td>
                            <td class="text-right">
                              <pre>{{
                                lineItem.summary.totalBillableTime.hours
                              }}</pre>
                            </td>
                            <td class="text-right">
                              <pre
                                >{{
                                  lineItem.summary.totalCompensation.amount
                                }} {{
                                  lineItem.summary.totalCompensation.currency
                                }}</pre
                              >
                            </td>
                          </tr>
                        </tbody>
                        <tfoot class="font-weight-medium v-row-group__header">
                          <tr>
                            <td class="text-left" :colspan="2">Gesamt</td>

                            <td class="text-right">
                              <strong>
                                <pre
                                  >{{
                                    item.payslipSummary.totalCompensation
                                      .amount
                                  }} {{
                                    item.payslipSummary.totalCompensation
                                      .currency
                                  }}</pre
                                >
                              </strong>
                            </td>
                          </tr>
                        </tfoot>
                      </template>
                    </v-simple-table>
                  </td>
                </template>
              </v-data-table>
            </v-col>
            <!-- <v-col cols="12" md="4" sm="12">
              <v-card>
                <v-card-text>
                  <div class="d-flex justify-space-between align-center">
                    <div>
                      <span class="">Geleistete Zeit</span>
                      <h4 class="text-h4 mb-2">
                        160<span
                          class="ml-1 font-weight-normal text--grey subtitle-1"
                          >h</span
                        >
                        0<span
                          class="ml-1 font-weight-normal text--grey subtitle-1"
                          >min</span
                        >
                      </h4>
                    </div>
                    <div>
                      <VueApexCharts
                        type="donut"
                        height="150"
                        width="150"
                        :options="timeSpendingChartConfig"
                        :series="timeSpendingChartSeries"
                      />
                    </div>
                  </div>
                </v-card-text>

                <v-divider></v-divider>
                <v-simple-table dense>
                  <template v-slot:default>
                    <thead class="v-row-group__header">
                      <tr>
                        <th class="text-left">Abrechnungsgruppe</th>
                        <th class="text-right">Anzahl in H</th>
                        <th class="text-right">Summe in EUR</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="lineItem in batchData.persons.lineItems"
                        :key="lineItem.id"
                      >
                        <td>{{ lineItem.title }}</td>
                        <td class="text-right">
                          {{ lineItem.summary.totalBillableTime.hours }}
                        </td>
                        <td class="text-right">
                          <pre
                            >{{ lineItem.summary.totalCompensation.amount }} {{
                              lineItem.summary.totalCompensation.currency
                            }}</pre
                          >
                        </td>
                      </tr>
                    </tbody>
                    <tfoot
                      class="font-weight-medium v-row-group__header rounded-b-lg"
                    >
                      <tr>
                        <td class="text-left">Gesamt</td>
                        <td class="text-right">
                          {{ batchData.summary.totalBillableTime.hours }}
                        </td>
                        <td class="text-right">
                          <strong>
                            <pre
                              >{{
                                batchData.summary.totalCompensation.amount
                              }} {{
                                batchData.summary.totalCompensation.currency
                              }}</pre
                            >
                          </strong>
                        </td>
                      </tr>
                    </tfoot></template
                  >
                </v-simple-table>
              </v-card>
            </v-col> -->
          </v-row>
          <v-row>
            <v-col cols="12">
              <Subheader icon="mdi-history" title="Logs"> </Subheader>
              <div class="">
                <v-timeline dense align-top>
                  <v-timeline-item
                    fill-dot
                    class="mb-2"
                    color="white"
                    icon-color="grey"
                    icon="mdi-pencil"
                    small
                  >
                    <v-card>
                      <v-card-text>
                        <v-textarea
                          v-model="helpers.commentForm.value"
                          dense
                          label="Kommentar hinzufügen"
                          hide-details="auto"
                          outlined
                          no-resize
                          auto-grow
                          rows="3"
                        >
                        </v-textarea>
                        <v-alert
                          v-if="helpers.commentForm.errorMessage"
                          dense
                          type="error"
                          text
                          class="mt-4 mb-0"
                        >
                          {{ helpers.commentForm.errorMessage }}</v-alert
                        >
                      </v-card-text>
                      <v-divider></v-divider>
                      <v-card-actions>
                        <!-- <v-checkbox
                          dense
                          disabled
                          label="Benachrichtigung per E-Mail senden"
                          persistent-hint
                          hint="Eine Benachrichtigung über diesen Eintrag wird per E-Mail an ausgewählte Personen gesendet."
                        ></v-checkbox> -->

                        <v-spacer></v-spacer
                        ><v-btn
                          class="mx-0"
                          color="success"
                          depressed
                          :loading="helpers.commentForm.isLoading"
                          :disabled="!helpers.commentForm.value"
                          @click="addComment"
                        >
                          Hinzufügen
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-timeline-item>

                  <v-slide-x-transition group>
                    <div
                      v-for="entry in logsAndComments"
                      :key="entry.id"
                      class="mb-2"
                    >
                      <v-timeline-item
                        color="white"
                        fill-dot
                        small
                        :icon-color="logEntryActionColor(entry.type)"
                        :icon="logEntryActionIcon(entry.type)"
                      >
                        <v-card flat class="elevation-2">
                          <v-card-text>
                            <v-row justify="space-between">
                              <v-col cols="7">
                                <strong>{{ entry.user.displayName }}</strong>
                                {{ logEntryActionText(entry.type) }}
                                <span v-if="entry.type === 'comment'">
                                  <br />{{ entry.text }}
                                </span>
                                <span v-if="entry.reason">
                                  <br />Grund: {{ entry.reason }}
                                </span>
                                <span
                                  v-if="entry.additionalData?.recipients?.text"
                                >
                                  <br />Empfänger:
                                  {{ entry.additionalData?.recipients?.text }}
                                </span>
                                <span v-if="entry.additionalData?.timestamp">
                                  <br />Zeitpunkt:
                                  {{
                                    timestampConverter(
                                      entry.additionalData.timestamp
                                    )
                                  }}
                                </span></v-col
                              >
                              <v-col class="text-right" cols="5">{{
                                timestampConverter(entry.timestamp)
                              }}</v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-timeline-item>
                    </div>
                  </v-slide-x-transition>
                  <v-divider></v-divider>
                </v-timeline>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
    <section>
      <custom-dialog
        v-if="helpers.dutyReportsView.dialogModel"
        @close="toggleViewMode('dutyReports')"
        title="Zu prüfende Dienstberichte in Abrechnungsperiode"
        width="xxxl"
        :errorMessage="helpers.dutyReportsView.errorMessage"
        :loading="helpers.dutyReportsView.loading"
        showCloseBtn
        hideActionBtn
        fullscreenOnMobile
      >
        <template v-slot:content>
          <v-card outlined>
            <reports-data-table
              :reports="warningDutyReports"
              showActionBtn
            ></reports-data-table>
          </v-card>
        </template>
      </custom-dialog>
    </section>
    <section>
      <custom-dialog
        v-if="helpers.timeSheetsView.dialogModel"
        @close="toggleViewMode('timeSheets')"
        title="Zu prüfende Stundenmeldungen in Abrechnungsperiode"
        width="xxxl"
        :errorMessage="helpers.timeSheetsView.errorMessage"
        :loading="helpers.timeSheetsView.loading"
        showCloseBtn
        hideActionBtn
        fullscreenOnMobile
      >
        <template v-slot:content>
          <v-card outlined>
            <time-sheets-data-table
              :timeSheets="warningTimeSheets"
              showActionBtn
            ></time-sheets-data-table>
          </v-card>
        </template>
      </custom-dialog>
    </section>
    <section>
      <custom-dialog
        v-if="helpers.instructPaymentDialog.model"
        @close="toggleInstructPaymentDialog()"
        title="Auszahlung anweisen"
        width="s"
        :btnAction="instructPayment"
        :loading="helpers.instructPaymentDialog.loading"
        :errorMessage="helpers.instructPaymentDialog.errorMessage"
        :btnDisabled="!helpers.instructPaymentDialog.formIsValid"
      >
        <template v-slot:content>
          <br />
          <v-form
            v-model="helpers.instructPaymentDialog.formIsValid"
            @submit.prevent="instructPayment()"
          >
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="helpers.instructPaymentDialog.recipients.text"
                  label="Empfänger"
                  hide-details="auto"
                  :rules="[rules.required]"
                  outlined
                  prepend-inner-icon="mdi-account-multiple"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="helpers.instructPaymentDialog.date"
                  type="date"
                  label="Datum"
                  hint="DD.MM.YYYY"
                  outlined
                  hide-details="auto"
                  :rules="[rules.required]"
                  required
                  prepend-inner-icon="mdi-calendar-start"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="helpers.instructPaymentDialog.time"
                  label="Zeit"
                  hint="HH:MM"
                  :rules="[rules.required]"
                  outlined
                  hide-details="auto"
                  type="time"
                  prepend-inner-icon="mdi-clock-start"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="helpers.instructPaymentDialog.reason"
                  label="Hinweise/Bemerkungen"
                  hide-details="auto"
                  outlined
                  :rows="3"
                  auto-grow
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </template>
      </custom-dialog>
    </section>
    <section>
      <custom-dialog
        v-if="helpers.recordPaymentDialog.model"
        @close="toggleRecordPaymentDialog()"
        title="Zahlungsstatus erfassen"
        width="s"
        :btnAction="recordPayment"
        :loading="helpers.recordPaymentDialog.loading"
        :errorMessage="helpers.recordPaymentDialog.errorMessage"
        :btnDisabled="!helpers.recordPaymentDialog.formIsValid"
      >
        <template v-slot:content>
          <br />
          <v-form
            v-model="helpers.recordPaymentDialog.formIsValid"
            @submit.prevent="recordPayment()"
          >
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="helpers.recordPaymentDialog.date"
                  type="date"
                  label="Datum"
                  hint="DD.MM.YYYY"
                  outlined
                  hide-details="auto"
                  :rules="[rules.required]"
                  required
                  prepend-inner-icon="mdi-calendar-start"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="helpers.recordPaymentDialog.time"
                  label="Zeit"
                  hint="HH:MM"
                  :rules="[rules.required]"
                  outlined
                  hide-details="auto"
                  type="time"
                  prepend-inner-icon="mdi-clock-start"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="helpers.recordPaymentDialog.reason"
                  label="Hinweise/Bemerkungen"
                  hide-details="auto"
                  outlined
                  :rows="3"
                  auto-grow
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </template>
      </custom-dialog>
    </section>
    <v-snackbar
      v-model="helpers.snackbar.isVisible"
      class="pb-4"
      bottom
      app
      timeout="3000"
      :color="helpers.snackbar.color"
      ><v-icon class="mr-1">mdi-check-circle</v-icon>
      {{ helpers.snackbar.message }}
    </v-snackbar>
    <section v-if="checkIfSupport">
      <support-tools :sources="code"></support-tools>
    </section>
  </div>
</template>

<script>
import { db, db1, functions, auth, Timestamp, FieldValue } from "@/firebase";
import {
  collection,
  query,
  where,
  // Timestamp,
  getCountFromServer,
  getDocs,
} from "firebase/firestore";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import Subheader from "@/components/_systemwide/Subheader.vue";
import CustomDialog from "@/components/_systemwide/CustomDialog.vue";
import SupportTools from "@/components/_system/helpers/SupportTools.vue";
import ReportsDataTable from "@/components/duty-management/ReportsDataTable.vue";
import TimeSheetsDataTable from "@/components/accounting/time-sheets/TimeSheetsDataTable.vue";
import PayrollStatusChip from "@/components/accounting/payroll/PayrollStatusChip.vue";
// import VueApexCharts from "vue-apexcharts";
import { ACCOUNTING } from "@/store/modules.js";
import { GET_PAYROLL_GROUPS } from "@/store/action-types.js";
// import {
//   SCHEDULING_CONFIG_EVENT_TYPES_create,
//   SCHEDULING_CONFIG_EVENT_TYPES_delete,
// } from "@/data/permission-types.js";

export default {
  name: "accounting-payroll-details",
  components: {
    NavigationBar,
    Subheader,
    CustomDialog,
    PayrollStatusChip,
    ReportsDataTable,
    TimeSheetsDataTable,
    SupportTools,
    // VueApexCharts,
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || "Feld ist erforderlich.",
      },
      showPersonTable: false,
      expanded: [],
      search: "",
      personTableHeaders: [{ text: "Name", value: "user.displayName" }],
      headers: [
        { text: "", value: "data-table-expand", width: "1%" },
        { text: "Name", value: "user.displayName" },
        {
          text: "Summe in EUR",
          value: "payslipSummary.totalCompensation.amount",
          align: "end",
        },
      ],
      helpers: {
        dataIsLoading: true,
        dutyReportsView: {
          dialogModel: false,
          errorMessage: "",
          loading: false,
        },
        timeSheetsView: {
          dialogModel: false,
          errorMessage: "",
          loading: false,
        },
        closeWarningsAction: {
          isLoading: false,
          errorMessage: "",
        },
        generatePayslipsAction: {
          isLoading: false,
          errorMessage: "",
        },
        requestPaymentAction: {
          isLoading: false,
          errorMessage: "",
        },
        updatePaymentStatusAction: {
          isLoading: false,
          errorMessage: "",
        },
        snackbar: {
          message: "",
          isVisible: false,
        },
        commentForm: {
          isLoading: false,
          value: "",
          errorMessage: "",
        },
        instructPaymentDialog: {
          model: false,
          formIsValid: false,
          errorMessage: "",
          loading: false,
          recipients: {
            text: "",
          },
          date: "",
          time: "",
          reason: "",
        },
        recordPaymentDialog: {
          model: false,
          formIsValid: false,
          errorMessage: "",
          loading: false,
          recipients: {
            text: "",
          },
          date: "",
          time: "00:00",
          reason: "",
        },
        payslipsTable: {
          expandedRows: [],
        },
      },

      sortDesc: true,
      sortBy: "title",

      batchData: {
        title: null,
        persons: [],
        period: {
          start: {
            timestamp: null,
          },
          end: {
            timestamp: null,
          },
        },
      },
      payslips: [],
      logs: [],
      comments: [],
      warningCounts: {
        dutyReports: {
          toBeReviewed: null,
          total: null,
          approved: null,
        },
        timeSheets: {
          toBeReviewed: null,
          total: null,
          approved: null,
        },
      },
      warningDutyReports: [],
      warningTimeSheets: [],
    };
  },
  computed: {
    actions() {
      const batchStatus = this.batchData ? this.batchData.status : null;
      return [
        // {
        //   title: "Löschen",
        //   //   permission: `${SCHEDULING_CONFIG_EVENT_TYPES_delete}`,
        //   icon: "mdi-delete",
        //   actionStyle: "textBtn",
        //   function: this.deleteItem,
        // },
        {
          title: "Exportieren",
          //   permission: `${SCHEDULING_CONFIG_EVENT_TYPES_create}`,
          icon: "mdi-file-export",
          actionStyle: "textBtn",
          disabled:
            batchStatus === "warningsToBeClosed" ||
            batchStatus === "payslipsToBeGenerated",
          function: () => {
            this.$router.push({
              name: "accounting-payroll-export",
            });
          },
        },
        // {
        //   title: "Löschen",
        //   //   permission: `${SCHEDULING_CONFIG_EVENT_TYPES_delete}`,
        //   icon: "mdi-delete",
        //   actionStyle: "textBtn",
        //   function: this.deleteItem,
        // },
        { divider: true },
        { actionStyle: "clipboardBtn" },
      ];
    },
    checkIfSupport() {
      return this.$store.getters["organization/checkIfSupport"];
    },
    code() {
      return [
        { title: "batchData", data: this.batchData },
        { title: "payrollGroups", data: this.payrollGroups },
        { title: "logsAndComments", data: this.logsAndComments },
      ];
    },
    payrollGroups() {
      // const payrollGroups =
      return this.$store.state.accounting.payrollGroups;

      // getTotalCompensation

      // if (!this.batchData) {
      //   return [];
      // }

      // return payrollGroups.map((payrollGroup) => {
      //   const totalAmount =
      //     this.batchData.summary.byPayrollType.find(
      //       (type) => type.id === payrollGroup.id
      //     ).totalCompensation.amount || 0;

      //   return {
      //     ...payrollGroup,
      //     totalBillableTime: {
      //       hours: 0,
      //       minutes: 0,
      //     },
      //     totalCompensation: {
      //       amount: totalAmount,
      //       currency:
      //         this.batchData.summary.totalCompensation.currency || "EUR",
      //     },
      //   };
      // });
    },
    logsAndComments() {
      return this.logs
        .concat(this.comments)
        .sort((a, b) => b.timestamp - a.timestamp);
    },
  },
  created() {
    this.initialize();
  },
  watch: {
    $route: "initialize",
  },
  methods: {
    async initialize() {
      await this.getBatch();
      await this.getPayrollGroups();
      await this.getLogs();
      await this.getComments();
      await this.getWarnings(false);
      this.helpers.dataIsLoading = false;
    },
    async getBatch() {
      try {
        const doc = await db
          .collection("organizations")
          .doc(this.$route.params.organizationId)
          .collection("payrollBatches")
          .doc(this.$route.params.itemId)
          .get();
        if (doc.exists) {
          this.batchData = doc.data();
          this.batchData.persons.sort((a, b) =>
            a.user.lastName.localeCompare(b.user.lastName)
          );
          //
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.log("Error getting document:", error);
        return false;
      }
    },
    async getPayrollGroups() {
      try {
        await this.$store.dispatch(`${ACCOUNTING}${GET_PAYROLL_GROUPS}`, {
          organizationId: this.$route.params.organizationId,
        });
      } catch (error) {
        console.log("Error getting payroll types:", error);
      }
    },
    async getWarnings(onlyCount = true) {
      const getWarningData = async (
        collectionName,
        statusField,
        startField
      ) => {
        const collectionRef = collection(
          db1,
          "organizations",
          this.$route.params.organizationId,
          collectionName
        );
        const toBeReviewedQuery = query(
          collectionRef,
          where(statusField, "in", ["toBeReviewed", "toBeProcessed"]),
          where(startField, ">=", this.batchData.period.start.timestamp),
          where(startField, "<=", this.batchData.period.end.timestamp)
        );
        const approvedQuery = query(
          collectionRef,
          where(statusField, "==", "approved"),
          where(startField, ">=", this.batchData.period.start.timestamp),
          where(startField, "<=", this.batchData.period.end.timestamp)
        );

        const [toBeReviewedSnapshot, approvedSnapshot] = await Promise.all([
          getCountFromServer(toBeReviewedQuery),
          getCountFromServer(approvedQuery),
        ]);

        let items = [];
        if (!onlyCount) {
          const querySnapshot = await getDocs(toBeReviewedQuery);
          querySnapshot.forEach((doc) => {
            items.push(doc.data());
          });
        }

        return {
          toBeReviewed: toBeReviewedSnapshot.data().count,
          approved: approvedSnapshot.data().count,
          total:
            toBeReviewedSnapshot.data().count + approvedSnapshot.data().count,
          items,
        };
      };

      const [dutyReports, timeSheets] = await Promise.all([
        getWarningData("dutyReports", "status", "duration.start.timestamp"),
        getWarningData(
          "timeSheets",
          "status",
          "billableDuration.start.timestamp"
        ),
      ]);

      this.warningCounts = {
        dutyReports,
        timeSheets,
      };

      if (!onlyCount) {
        this.warningDutyReports = dutyReports.items;
        this.warningTimeSheets = timeSheets.items;
      }
    },
    async getLogs() {
      try {
        const querySnapshot = await db
          .collection("organizations")
          .doc(this.$route.params.organizationId)
          .collection("payrollBatches")
          .doc(this.$route.params.itemId)
          .collection("logs")
          .get();

        const logs = [];
        querySnapshot.forEach((doc) => {
          logs.push(doc.data());
        });
        this.logs = logs;
      } catch (error) {
        console.log("Error getting document:", error);
        return false;
      }
    },
    async getComments() {
      try {
        const querySnapshot = await db
          .collection("organizations")
          .doc(this.$route.params.organizationId)
          .collection("payrollBatches")
          .doc(this.$route.params.itemId)
          .collection("comments")
          .get();

        const comments = [];
        querySnapshot.forEach((doc) => {
          comments.push(doc.data());
        });
        this.comments = comments;
      } catch (error) {
        console.log("Error getting document:", error);
        return false;
      }
    },
    toggleRecordPaymentDialog() {
      this.helpers.recordPaymentDialog.model =
        !this.helpers.recordPaymentDialog.model;
    },
    toggleInstructPaymentDialog() {
      this.helpers.instructPaymentDialog.model =
        !this.helpers.instructPaymentDialog.model;
    },
    convertToTimestamp(date, time) {
      const input = date + " " + time;
      return Timestamp.fromDate(new Date(input.replace(/-/g, "/")));
    },
    recordPayment() {
      this.helpers.recordPaymentDialog.loading = true;
      this.helpers.recordPaymentDialog.errorMessage = "";
      const callFunction = functions.httpsCallable(
        "accounting-recordPaymentForPayrollBatch"
      );
      return callFunction({
        organizationId: this.$route.params.organizationId,
        batchId: this.batchData.meta.id,
        reason: this.helpers.recordPaymentDialog.reason,
        timestamp: this.convertToTimestamp(
          this.helpers.recordPaymentDialog.date,
          this.helpers.recordPaymentDialog.time
        ),
      })
        .then((result) => {
          if (result.data.success) {
            this.getBatch();
            this.getLogs();
            this.helpers.recordPaymentDialog.loading = false;
            this.helpers.snackbar.message = "Status aktualisiert.";
            this.helpers.snackbar.isVisible = true;
          }
          this.toggleRecordPaymentDialog();
        })
        .catch((error) => {
          this.helpers.recordPaymentDialog.loading = false;
          this.helpers.recordPaymentDialog.errorMessage = error.message;
          console.error("Error updating payroll status: ", error);
        });
    },
    instructPayment() {
      this.helpers.instructPaymentDialog.loading = true;
      this.helpers.instructPaymentDialog.errorMessage = "";
      const callFunction = functions.httpsCallable(
        "accounting-instructPaymentForPayrollBatch"
      );
      return callFunction({
        organizationId: this.$route.params.organizationId,
        batchId: this.batchData.meta.id,
        recipients: this.helpers.instructPaymentDialog.recipients,
        reason: this.helpers.instructPaymentDialog.reason,
        timestamp: this.convertToTimestamp(
          this.helpers.instructPaymentDialog.date,
          this.helpers.instructPaymentDialog.time
        ),
      })
        .then((result) => {
          if (result.data.success) {
            this.getBatch();
            this.getLogs();
            this.helpers.instructPaymentDialog.loading = false;
            this.helpers.snackbar.message = "Status aktualisiert.";
            this.helpers.snackbar.isVisible = true;
          }
          this.toggleInstructPaymentDialog();
        })
        .catch((error) => {
          this.helpers.instructPaymentDialog.loading = false;
          this.helpers.instructPaymentDialog.errorMessage = error.message;
          console.error("Error updating payroll status: ", error);
        });
    },
    addComment() {
      if (this.helpers.commentForm.value != "") {
        this.helpers.commentForm.isLoading = true;
        const commentRef = db
          .collection("organizations")
          .doc(this.$route.params.organizationId)
          .collection("payrollBatches")
          .doc(this.$route.params.itemId)
          .collection("comments")
          .doc();

        const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

        return commentRef
          .set({
            user: {
              displayName: auth.currentUser.displayName,
              uid: auth.currentUser.uid,
            },
            type: "comment",
            text: this.helpers.commentForm.value,
            mentions: [],
            timestamp: FieldValue.serverTimestamp(),
            id: commentRef.id,
          })
          .then(async () => {
            this.getComments();
            await delay(800);
            this.helpers.commentForm.value = "";
            this.helpers.commentForm.isLoading = false;
          })
          .catch(async (error) => {
            console.error("Error adding document: ", error);
            await delay(800);
            this.helpers.commentForm.errorMessage = error.message;
            this.helpers.commentForm.isLoading = false;
          });
      } else {
        this.helpers.commentForm.errorMessage =
          "Fehler: Es sind nicht alle Pflichtangaben ausgefüllt.";
      }
    },
    closeWarnings() {
      this.helpers.closeWarningsAction.isLoading = true;
      this.helpers.closeWarningsAction.errorMessage = "";
      const callFunction = functions.httpsCallable(
        "accounting-closeWarningsInPayrollBatch"
      );
      return callFunction({
        organizationId: this.$route.params.organizationId,
        batchId: this.batchData.meta.id,
      })
        .then(() => {
          this.getBatch();
          this.getLogs();
          this.helpers.closeWarningsAction.isLoading = false;
          this.helpers.snackbar.message = "Warnungen geschlossen.";
          this.helpers.snackbar.isVisible = true;
        })
        .catch((error) => {
          this.helpers.closeWarningsAction.isLoading = false;
          this.helpers.closeWarningsAction.errorMessage = error.message;
          console.error("Error approving timeSheet: ", error);
        });
    },
    generatePayslips() {
      this.helpers.generatePayslipsAction.isLoading = true;
      this.helpers.generatePayslipsAction.errorMessage = "";
      const callFunction = functions.httpsCallable(
        "accounting-generatePayslipsInPayrollBatch"
      );
      return callFunction({
        organizationId: this.$route.params.organizationId,
        batchId: this.batchData.meta.id,
      })
        .then(() => {
          this.getBatch();
          this.getLogs();
          this.helpers.generatePayslipsAction.isLoading = false;
          this.helpers.snackbar.message = "Abrechnungszettel generiert.";
          this.helpers.snackbar.isVisible = true;
        })
        .catch((error) => {
          this.helpers.generatePayslipsAction.isLoading = false;
          this.helpers.generatePayslipsAction.errorMessage = error.message;
          console.error("Error generating payslips: ", error);
        });
    },
    getPayrollTypeText(type) {
      switch (type) {
        case "regular":
          return "Regulärer Turnus";
        case "special":
          return "Sonderzahlung";
        default:
          return "Unbekannt";
      }
    },
    toggleViewMode(type) {
      if (type === "dutyReports") {
        this.helpers.dutyReportsView.dialogModel =
          !this.helpers.dutyReportsView.dialogModel;
      } else if (type === "timeSheets") {
        this.helpers.timeSheetsView.dialogModel =
          !this.helpers.timeSheetsView.dialogModel;
      }
    },
    logEntryActionText(type) {
      switch (type) {
        case "created":
          return "hat den Abrechnungsstapel erstellt.";
        case "payslipsToBeGenerated":
          return "hat Warnungen des Abrechnungsstapels geschlossen.";
        case "payslipsGenerated":
          return "hat die Abrechnungszettel für diesen Abrechnungsstapel erstellt.";
        case "waitingOnPayment":
          return "hat die Auszahlung angewiesen.";
        case "paid":
          return "hat die Auszahlung bestätigt.";
        case "comment":
          return "hat einen Kommentar hinzugefügt:";
        default:
          break;
      }
    },
    logEntryActionColor(type) {
      switch (type) {
        case "created":
          return "primary";
        case "payslipsToBeGenerated":
          return "purple";
        case "payslipsGenerated":
          return "primary";
        case "waitingOnPayment":
        case "paid":
          return "green";
        case "comment":
          return "grey";
        default:
          break;
      }
    },
    logEntryActionIcon(type) {
      switch (type) {
        case "created":
          return "mdi-plus";
        case "payslipsToBeGenerated":
          return "mdi-check-all";
        case "payslipsGenerated":
          return "mdi-plus-box-multiple";
        case "waitingOnPayment":
          return "mdi-cash-clock";
        case "paid":
          return "mdi-cash-check";
        case "comment":
          return "mdi-comment-text";
        default:
          break;
      }
    },
    timestampConverter(timestamp) {
      const options = {
        weekday: "short",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      };
      return timestamp.toDate().toLocaleDateString("de-DE", options);
    },
    getTotalItemsCount(lineItems) {
      return lineItems.reduce((acc, item) => acc + item.totalItemsCount, 0);
    },
    editItem() {
      //   this.$router.push({
      //     name: "scheduling-config-tab-event-types-event-type-edit",
      //     params: {
      //       organizationId: this.$route.params.organizationId,
      //       itemId: this.$route.params.itemId,
      //     },
      //   });
    },
    deleteItem() {
      alert(
        "Fehler: Das Löschen von Abrechnungsstapeln ist nicht möglich. Bitte kontaktiere den Support."
      );
    },
  },
};
</script>

<style></style>
