<template>
  <div>
    <NavigationBar
      showBackBtn
      titel="Abrechnungsstapel exportieren"
      isPreview
    ></NavigationBar>
    <section>
      <template>
        <v-container fluid>
          <v-row class="match-height">
            <v-col>
              <v-alert
                coored-border
                border="left"
                color="teal"
                icon="mdi-flask"
                dark
              >
                Bitte beachte, dass sich diese Funktion in Vorschau (Beta)
                befindet. Zum aktuellen Zeitpunkt empfehlen wir für den Export
                die Verwendung von Google Chrome. Ebenfalls sollten die
                ausgegebenen Daten nach Export erneut überprüft werden.
              </v-alert>
            </v-col>
            <v-col cols="12">
              <v-stepper v-model="helpers.stepperState" vertical>
                <v-stepper-step :complete="helpers.stepperState > 1" step="1">
                  Export konfigurieren
                </v-stepper-step>

                <v-stepper-content step="1">
                  <v-card outlined>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" sm="12" md="5">
                          <v-text-field
                            v-model.trim="exportConfig.fileTitle"
                            label="Dateiname"
                            required
                            :rules="[rules.required]"
                            outlined
                            hide-details="auto"
                            prepend-inner-icon="mdi-file-excel"
                            suffix=".xlsx"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model.trim="exportConfig.headerText"
                            label="Kopfzeile"
                            outlined
                            disabled
                            hide-details="auto"
                            prepend-inner-icon="mdi-page-layout-header"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model.trim="exportConfig.footerText"
                            label="Fußzeile"
                            outlined
                            disabled
                            hide-details="auto"
                            prepend-inner-icon="mdi-page-layout-footer"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          Ausrichtung
                          <v-btn-toggle
                            v-model="exportConfig.orientation"
                            dense
                            mandatory
                            class="ml-2"
                          >
                            <v-btn value="landscape" small disabled
                              ><v-icon left>mdi-crop-landscape</v-icon
                              >Querformat</v-btn
                            >
                            <v-btn value="portrait" small disabled
                              ><v-icon left>mdi-crop-portrait</v-icon
                              >Hochformat</v-btn
                            >
                          </v-btn-toggle>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                      <v-spacer></v-spacer>

                      <v-btn
                        depressed
                        color="primary"
                        @click="helpers.stepperState = 2"
                        :disabled="!exportConfig.fileTitle"
                      >
                        Weiter
                        <v-icon right>mdi-chevron-right</v-icon>
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-stepper-content>

                <v-stepper-step :complete="helpers.stepperState > 1" step="2">
                  Personen im Export sortieren
                </v-stepper-step>

                <v-stepper-content step="2">
                  <v-card outlined>
                    <v-data-table
                      :headers="personSortHeaders"
                      :items="personsSortOrder"
                      item-key="user.uid"
                      sort-by="user.sequenceNumber"
                      dense
                    >
                      <template v-slot:item.user.sequenceNumber="{ item }">
                        <v-text-field
                          v-model.number="item.user.sequenceNumber"
                          type="number"
                          dense
                          outlined
                          hide-details
                          class="my-2"
                          @input="updateSequenceNumber(item)"
                        ></v-text-field>
                      </template>
                    </v-data-table>

                    <v-divider></v-divider>
                    <v-card-actions>
                      <v-btn
                        @click="helpers.stepperState = 1"
                        depressed
                        :disabled="helpers.exportIsLoading"
                      >
                        <v-icon left>mdi-chevron-left</v-icon>
                        Zurück
                      </v-btn>
                      <v-spacer></v-spacer>

                      <v-btn
                        depressed
                        color="primary"
                        @click="processSequenceNumbers"
                      >
                        Weiter
                        <v-icon right>mdi-chevron-right</v-icon>
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-stepper-content>

                <v-stepper-step :complete="helpers.stepperState > 2" step="3">
                  Export starten
                </v-stepper-step>

                <v-stepper-content step="3">
                  <v-card outlined>
                    <v-card-text>
                      <v-alert text color="primary" type="info">
                        <div class="title">Hinweis</div>
                        <div class="mt-2">
                          Nach Abschluss der Verarbeitung wird die Datei als
                          Excel-Datei (.xls-Datei) automatisch heruntergeladen.
                          Lade die Seite nicht neu und schließe dieses Fenster
                          nicht, bis der Download abgeschlossen ist.
                        </div>
                      </v-alert>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                      <v-btn
                        @click="helpers.stepperState = 2"
                        depressed
                        :disabled="helpers.exportIsLoading"
                      >
                        <v-icon left>mdi-chevron-left</v-icon>
                        Zurück
                      </v-btn>
                      <v-spacer></v-spacer>

                      <v-btn
                        color="primary"
                        @click="exportWorkbook"
                        depressed
                        :loading="helpers.exportIsLoading"
                      >
                        Export starten
                        <v-icon right>mdi-chevron-right</v-icon>
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-stepper-content>
              </v-stepper>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>

    <section v-if="checkIfSupport">
      <support-tools :sources="code"></support-tools>
    </section>
  </div>
</template>

<script>
import { db } from "@/firebase";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import ExcelJS from "exceljs";
import SupportTools from "@/components/_system/helpers/SupportTools.vue";

export default {
  name: "accounting-payroll-export",
  components: {
    NavigationBar,
    SupportTools,
  },
  data() {
    return {
      helpers: {
        stepperState: 1,
        exportIsLoading: false,
      },
      rules: {
        required: (value) => !!value || "Erforderlich.",
      },
      exportConfig: {
        fileTitle: "",
        headerText: "",
        footerText: "",
        orientation: "landscape",
      },
      batchData: {},
      payslipsData: [],
      personsSortOrder: [],
      dutyReportsStore: [],
      personSortHeaders: [
        { text: "Lfd. Nr.", value: "user.sequenceNumber", width: "15%" },
        { text: "Nachname", value: "user.lastName" },
        { text: "Vorname", value: "user.firstName" },
      ],
    };
  },
  computed: {
    checkIfSupport() {
      return this.$store.getters["organization/checkIfSupport"];
    },
    code() {
      return [
        { title: "batchData", data: this.batchData },
        { title: "payslipsData", data: this.payslipsData },
      ];
    },
  },
  created() {
    this.initialize();
  },
  watch: {
    $route: "initialize",
  },
  methods: {
    async initialize() {
      // this.getDemoData();
      await this.getBatch();
      await this.getPayslips();
      this.exportConfig.fileTitle = this.batchData.title;
      this.preparePersonsSortOrder();
    },
    updateSequenceNumber(item) {
      const person = this.personsSortOrder.find(
        (p) => p.user.uid === item.user.uid
      );
      if (person) {
        person.user.sequenceNumber = item.user.sequenceNumber;
      }
    },
    preparePersonsSortOrder() {
      this.personsSortOrder = this.batchData.persons
        .map((person) => ({ ...person, user: { ...person.user } })) // Create a deep copy
        .sort((a, b) => {
          if (a.user.lastName < b.user.lastName) return -1;
          if (a.user.lastName > b.user.lastName) return 1;
          return 0;
        })
        .map((person, index) => {
          person.user.sequenceNumber = index + 1;
          return person;
        });
    },
    processSequenceNumbers() {
      // Sort by sequenceNumber
      this.personsSortOrder.sort((a, b) => {
        if (a.user.sequenceNumber !== b.user.sequenceNumber) {
          return a.user.sequenceNumber - b.user.sequenceNumber;
        }
        // If sequenceNumber is the same, sort by lastName
        if (a.user.lastName < b.user.lastName) return -1;
        if (a.user.lastName > b.user.lastName) return 1;
        return 0;
      });

      // Reassign sequenceNumbers to ensure they are unique and in order, starting from 1
      this.personsSortOrder.forEach((person, index) => {
        person.user.sequenceNumber = index + 1;
      });

      // Update stepper state
      this.helpers.stepperState = 3;
    },
    async getBatch() {
      try {
        const doc = await db
          .collection("organizations")
          .doc(this.$route.params.organizationId)
          .collection("payrollBatches")
          .doc(this.$route.params.itemId)
          .get();
        if (doc.exists) {
          this.batchData = doc.data();
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.log("Error getting document:", error);
        return false;
      }
    },
    async getPayslips() {
      try {
        const querySnapshot = await db
          .collection("organizations")
          .doc(this.$route.params.organizationId)
          .collection("payslips")
          .where("batch.id", "==", this.$route.params.itemId)
          .get();
        querySnapshot.forEach((doc) => {
          this.payslipsData.push(doc.data());
        });
      } catch (error) {
        console.log("Error getting payslips:", error);
        return false;
      }
    },
    async getDutyReports(ids) {
      try {
        const promises = ids.map((id) =>
          db
            .collection("organizations")
            .doc(this.$route.params.organizationId)
            .collection("dutyReports")
            .doc(id)
            .get()
        );

        const docs = await Promise.all(promises);

        docs.forEach((doc) => {
          if (doc.exists) {
            this.dutyReportsStore.push(doc.data());
          } else {
            console.log("No such document:", doc.id);
          }
        });
      } catch (error) {
        console.log("Error getting documents:", error);
        return false;
      }
    },
    async exportWorkbook() {
      this.helpers.exportIsLoading = true;
      const workbook = new ExcelJS.Workbook();
      workbook.title = this.exportConfig.fileTitle;
      workbook.creator = "Mira One";

      for (const payrollGroup of this.batchData.payrollGroups.data) {
        console.log("Processing payrollGroup:", payrollGroup.title);

        const worksheet = this.createWorksheet(workbook, payrollGroup);

        this.populateBaseHeaders(worksheet, payrollGroup);
        this.populateNameRows(worksheet);

        const relevantTimeSheets = (this.payslipsData || []).reduce(
          (acc, payslip) => {
            const index =
              this.batchData.payrollGroups.data.indexOf(payrollGroup);

            const lineItems = payslip.lineItems[index];

            if (lineItems && lineItems.inputs && lineItems.inputs.data) {
              acc.push(...lineItems.inputs.data);
            }
            return acc;
          },
          []
        );
        const itemHeaders = await this.computeItemHeaders(
          payrollGroup,
          relevantTimeSheets
        );
        this.populateItemHeaders(worksheet, itemHeaders);

        this.populateItemCells(worksheet, itemHeaders);

        this.addSums(worksheet, itemHeaders.length);
      }

      const buffer = await workbook.xlsx.writeBuffer();
      this.startDownload(buffer);

      this.helpers.exportIsLoading = false;
    },
    createWorksheet(workbook, payrollGroup) {
      return workbook.addWorksheet(payrollGroup.title, {
        properties: {
          tabColor: { argb: this.hexToARGB(payrollGroup.color) },
        },
        pageSetup: { paperSize: 9, orientation: "landscape" },
        headerFooter: {
          firstHeader: this.exportConfig.headerText,
          firstFooter: this.exportConfig.footerText,
        },
      });
    },
    populateBaseHeaders(worksheet, payrollGroup) {
      // Basic info of worksheet in the left corner
      worksheet.mergeCells("A2:B2");
      worksheet.getCell("A2").value = this.batchData.title;
      worksheet.getCell("A2").font = { bold: true };
      worksheet.getCell("A2").alignment = {
        horizontal: "center",
        vertical: "middle",
        shrinkToFit: true,
      };
      worksheet.getCell("A2").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        right: { style: "thin" },
      };
      worksheet.getCell("A2").fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "fff2f2f2" },
      };

      worksheet.mergeCells("A3:B3");
      worksheet.getCell("A3").value = this.timestampConverter(
        this.batchData.period.start.timestamp
      );
      worksheet.getCell("A3").font = { bold: true };
      worksheet.getCell("A3").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("A3").border = {
        left: { style: "thin" },
        right: { style: "thin" },
      };
      worksheet.getCell("A3").fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "fff2f2f2" },
      };

      worksheet.mergeCells("A4:B4");
      worksheet.getCell("A4").value = this.timestampConverter(
        this.batchData.period.end.timestamp
      );
      worksheet.getCell("A4").font = { bold: true };
      worksheet.getCell("A4").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("A4").border = {
        left: { style: "thin" },
        right: { style: "thin" },
      };
      worksheet.getCell("A4").fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "fff2f2f2" },
      };

      worksheet.mergeCells("A5:B5");
      worksheet.getCell("A5").value = payrollGroup.title;
      worksheet.getCell("A5").font = { bold: true };
      worksheet.getCell("A5").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      worksheet.getCell("A5").border = {
        left: { style: "thin" },
        right: { style: "thin" },
        bottom: { style: "thin" },
      };
      worksheet.getCell("A5").fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "fff2f2f2" },
      };
      // Basic headers for sequence no. and name columns

      worksheet.getCell("A6").value = "Lfd. Nr.";
      worksheet.getColumn("A").width = 8;
      worksheet.getColumn("A").alignment = { horizontal: "center" };
      worksheet.getCell("A6").font = { bold: true };
      worksheet.getCell("A6").fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "fff2f2f2" },
      };
      worksheet.getCell("A6").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      worksheet.getCell("B6").value = "Name";
      worksheet.getColumn("B").width = 20;
      worksheet.getCell("B6").font = { bold: true };
      worksheet.getCell("B6").fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "fff2f2f2" },
      };
      worksheet.getCell("B6").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      worksheet.getCell("C6").value = "Vorname";
      worksheet.getColumn("C").width = 20;
      worksheet.getCell("C6").font = { bold: true };
      worksheet.getCell("C6").fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "fff2f2f2" },
      };
      worksheet.getCell("C6").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      // switch (payrollGroup.displayType) {
      //   case "dutyTime":
      worksheet.mergeCells("C2:D2");
      worksheet.getCell("C2").value = "Einsatz-Nr. / Datum";
      worksheet.getCell("C2").font = { bold: true };
      worksheet.getCell("C2").alignment = { horizontal: "right" };
      worksheet.getCell("C2").border = {
        top: { style: "thin" },
        left: { style: "thin" },
        right: { style: "thin" },
      };
      worksheet.getCell("C2").fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "fff2f2f2" },
      };

      worksheet.mergeCells("C3:D3");
      worksheet.getCell("C3").value = "Uhrzeit (von - bis)";
      worksheet.getCell("C3").font = { bold: true };
      worksheet.getCell("C3").alignment = { horizontal: "right" };
      worksheet.getCell("C3").border = {
        left: { style: "thin" },
        right: { style: "thin" },
      };
      worksheet.getCell("C3").fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "fff2f2f2" },
      };

      worksheet.mergeCells("C4:D4");
      worksheet.getCell("C4").value = "Alarmierungsstichwort";
      worksheet.getCell("C4").font = { bold: true };
      worksheet.getCell("C4").alignment = { horizontal: "right" };
      worksheet.getCell("C4").border = {
        left: { style: "thin" },
        right: { style: "thin" },
      };
      worksheet.getCell("C4").fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "fff2f2f2" },
      };

      worksheet.mergeCells("C5:D5");
      worksheet.getCell("C5").value = "Fahrzeuge";
      worksheet.getCell("C5").font = { bold: true };
      worksheet.getCell("C5").alignment = { horizontal: "right" };
      worksheet.getCell("C5").border = {
        left: { style: "thin" },
        right: { style: "thin" },
        bottom: { style: "thin" },
      };
      worksheet.getCell("C5").fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "fff2f2f2" },
      };

      //     break;
      //   case "dutyTimeWithoutUnits":
      //     break;
      //   case "courses":
      //     break;
      //   case "other":
      //   case "default":
      //   default:
      //     worksheet.mergeCells("C2:D2");
      //     worksheet.getCell("C2").value = "Einsatz-Nr. / Datum";
      //     worksheet.getCell("C2").font = { bold: true };
      //     worksheet.getCell("C2").alignment = { horizontal: "right" };
      //     worksheet.getCell("C2").border = {
      //       top: { style: "thin" },
      //       left: { style: "thin" },
      //       right: { style: "thin" },
      //     };
      //     worksheet.getCell("C2").fill = {
      //       type: "pattern",
      //       pattern: "solid",
      //       fgColor: { argb: "fff2f2f2" },
      //     };

      //     worksheet.mergeCells("C3:D3");
      //     worksheet.getCell("C3").value = "Uhrzeit (von - bis)";
      //     worksheet.getCell("C3").font = { bold: true };
      //     worksheet.getCell("C3").alignment = { horizontal: "right" };
      //     worksheet.getCell("C3").border = {
      //       left: { style: "thin" },
      //       right: { style: "thin" },
      //     };
      //     worksheet.getCell("C3").fill = {
      //       type: "pattern",
      //       pattern: "solid",
      //       fgColor: { argb: "fff2f2f2" },
      //     };

      //     break;
      // }

      worksheet.getRow(6).font = { bold: true };
      worksheet.getRow(6).alignment = {
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      };
      worksheet.getRow(6).height = 30;
      // worksheet.getRow(6).fill = {
      //   type: "pattern",
      //   pattern: "solid",
      //   fgColor: { argb: "fff2f2f2" },
      // };
      // worksheet.getRow(6).border = {
      //   top: { style: "thin" },
      //   left: { style: "thin" },
      //   bottom: { style: "thin" },
      //   right: { style: "thin" },
      // };
    },
    populateNameRows(worksheet) {
      // Sort persons by lastName
      const sortedPersons = this.personsSortOrder.sort(
        (a, b) => a.user.sequenceNumber - b.user.sequenceNumber
      );

      // Create rows based on sorted persons
      const rows = sortedPersons.map((person) => ({
        sequenceNumber: person.user.sequenceNumber,
        lastName: person.user.lastName,
        firstName: person.user.firstName,
      }));

      // Add rows to worksheet
      for (const row of rows) {
        const newRow = worksheet.addRow([
          row.sequenceNumber,
          row.lastName,
          row.firstName,
        ]);

        // Set thin borders for each cell in the new row
        newRow.eachCell({ includeEmpty: true }, (cell, colNumber) => {
          // Set thin borders for each cell
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };

          // Cells of column A should have grey fill
          if (colNumber === 1) {
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "fff2f2f2" },
            };
          }
        });
      }
    },
    async computeItemHeaders(payrollGroup, relevantTimeSheets) {
      // Step 1: Get required dutyReports
      const dutyReportIds = relevantTimeSheets
        .map((ts) => ts.connectedDutyReportId)
        .filter((value, index, self) => self.indexOf(value) === index)
        .filter((value) => value);

      await this.getDutyReports(dutyReportIds);

      // Step 2: Create new helper array "itemHeadersHelper"
      const addedDutyReportIds = new Set();

      const itemHeadersHelper = relevantTimeSheets
        .sort(
          (a, b) =>
            a.billableDuration.start.timestamp -
            b.billableDuration.start.timestamp
        )
        .map((ts) => {
          const headerType = ts.connectedDutyReportId
            ? "dutyReport"
            : "timesheet";
          const dutyReportId = ts.connectedDutyReportId || "";

          if (headerType === "dutyReport") {
            if (addedDutyReportIds.has(dutyReportId)) {
              return null; // Skip this item if dutyReportId is already added
            }
            addedDutyReportIds.add(dutyReportId);
          }

          return {
            headerType,
            dutyReportId,
            timeSheetId: ts.id,
            dutyReportData:
              headerType === "dutyReport"
                ? this.dutyReportsStore.find(
                    (dr) => dr.meta.id === dutyReportId
                  )
                : null,
            timeSheetsData:
              headerType === "dutyReport"
                ? relevantTimeSheets.filter(
                    (item) => item.connectedDutyReportId === dutyReportId
                  )
                : [ts],
          };
        })
        .filter((item) => item !== null); // Remove null items

      // Step 3: Create new helper array "itemHeaders"
      const itemHeaders = itemHeadersHelper.map((helper) => {
        if (helper.headerType === "dutyReport") {
          const dutyReport = helper.dutyReportData;
          if (!dutyReport) {
            console.error(
              `Duty report not found for id: ${helper.dutyReportId}`
            );
            return {
              url: `https://app.mira-one.com/de/organizations/${this.$route.params.organizationId}/duty-management/reports`,
              incidentNumber: "",
              startDate: "",
              time: "",
              incidentCode: "",
              units: "",
              dutyReportData: null,
              timeSheetsData: [],
            };
          }
          const payrollType = payrollGroup.payrollTypes.data.find(
            (pt) => pt.meta.id === dutyReport.payrollType.id
          );

          const payrollTypeShortTitle = payrollType
            ? payrollType.shortTitle
            : "N/A";
          return {
            url: `https://app.mira-one.com/de/organizations/${this.$route.params.organizationId}/duty-management/reports/${dutyReport.meta.id}`,
            incidentNumber: dutyReport?.incident.number || "",
            startDate: `${
              this.getDay(dutyReport.duration.start.timestamp) + "."
            }`,
            time: `${
              this.getTime(dutyReport.duration.start.timestamp) +
              "-" +
              this.getTime(dutyReport.duration.end.timestamp)
            }`,
            incidentCode: dutyReport?.incident.code || payrollTypeShortTitle,
            units: this.getUnitsDisplay(dutyReport.units.data) || "",
            dutyReportData: dutyReport,
            timeSheetsData: helper.timeSheetsData,
          };
        } else {
          const timeSheet = helper.timeSheetsData[0];
          if (!timeSheet) {
            console.error(`Time sheet not found for id: ${helper.timeSheetId}`);
            return {
              url: `https://app.mira-one.com/de/organizations/${this.$route.params.organizationId}/accounting/time-sheets`,
              incidentNumber: "",
              startDate: "",
              time: "",
              incidentCode: "",
              units: "",
              dutyReportData: null,
              timeSheetsData: [],
            };
          }

          const payrollTypeShortTitle = timeSheet.payrollType.shortTitle
            ? timeSheet.payrollType.shortTitle
            : "N/A";

          return {
            url: `https://app.mira-one.com/de/organizations/${this.$route.params.organizationId}/accounting/time-sheets/${timeSheet.meta.id}`,
            incidentNumber: "",
            startDate: `${
              this.getDay(timeSheet.billableDuration.start.timestamp) + "."
            }`,
            time: `${
              this.getTime(timeSheet.billableDuration.start.timestamp) +
              "-" +
              this.getTime(timeSheet.billableDuration.end.timestamp)
            }`,
            incidentCode: payrollTypeShortTitle,
            units: timeSheet.additionalData?.assignedPosition?.unit?.title,
            dutyReportData: null,
            timeSheetsData: [{ ...timeSheet }],
          };
        }
      });

      // You can now use itemHeaders as needed, for example, to populate the worksheet
      return itemHeaders;
    },
    populateItemHeaders(worksheet, itemHeaders) {
      // You can now use itemHeaders as needed, for example, to populate the worksheet
      // Add itemHeaders to worksheet as row headers starting from "E2"
      itemHeaders.forEach((header, index) => {
        const startColumn = 69 + index; // 69 is the ASCII code for 'E'
        const properties = [
          header.url,
          header.incidentNumber
            ? `${header.incidentNumber} / ${header.startDate}`
            : header.startDate,
          header.time,
          header.incidentCode,
          header.units,
          "Erfasste\r\nStunden",
        ];

        properties.forEach((value, propertyIndex) => {
          const columnLetter = String.fromCharCode(startColumn);
          const rowNumber = 1 + propertyIndex; // Start from row 2 and increment for each property

          const cell = worksheet.getCell(`${columnLetter}${rowNumber}`);
          worksheet.getColumn(columnLetter).width = 13;

          if (propertyIndex === 0) {
            cell.value = {
              formula: `=HYPERLINK("${value}", "In Mira One öffnen")`,
            };
            cell.font = { underline: true, color: { theme: 10 } };
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "ffebf1de" },
            };
          } else {
            cell.value = value;
            cell.font = { bold: true };
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "fff2f2f2" },
            };
          }

          cell.alignment = { horizontal: "center", shrinkToFit: true };
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
          if (propertyIndex === 5) {
            cell.alignment = {
              vertical: "middle",
              horizontal: "center",
              wrapText: true,
            };
          }
        });
      });
    },
    populateItemCells(worksheet, itemHeaders) {
      // Start from column E and go to the right
      itemHeaders.forEach((header, columnIndex) => {
        const startColumn = 69 + columnIndex; // 69 is the ASCII code for 'E'

        // Create a map to store the sum of HOURS for each person and dutyReport
        const hoursMap = new Map();

        // For each itemHeader, look at timeSheetsData
        header.timeSheetsData.forEach((timeSheet) => {
          const person = this.personsSortOrder.find(
            (p) => p.user.uid === timeSheet.user.uid
          );
          if (!person) {
            console.error(
              `Person not found for user.uid: ${timeSheet.user.uid}`
            );
            return;
          }
          const sequenceNumber = person.user.sequenceNumber;
          const key = `${sequenceNumber}-${
            header.dutyReportId || "no-duty-report"
          }`;

          // Sum the HOURS for each person and dutyReport combination
          if (!hoursMap.has(key)) {
            hoursMap.set(key, 0);
          }
          hoursMap.set(
            key,
            hoursMap.get(key) + timeSheet.totalSalary.baseVariables.HOURS
          );
        });

        // Set the cell values based on the aggregated HOURS
        hoursMap.forEach((hours, key) => {
          const [sequenceNumber] = key.split("-");
          const rowNumber = parseInt(sequenceNumber) + 6; // only + 6 because sequence number will never be 0
          const cell = worksheet.getCell(
            `${String.fromCharCode(startColumn)}${rowNumber}`
          );
          cell.value = hours;
        });

        this.personsSortOrder.forEach((person, rowIndex) => {
          const rowNumber = rowIndex + 7;
          const cell = worksheet.getCell(
            `${String.fromCharCode(startColumn)}${rowNumber}`
          );
          cell.alignment = { horizontal: "center" };
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        });
      });
    },
    addSums(worksheet, columnCount) {
      const personsCount = this.personsSortOrder.length;

      worksheet.getColumn("D").width = 8;
      worksheet.getCell("D6").value = "Stunden\r\ngesamt";
      worksheet.getCell("D6").fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "fff2f2f2" },
      };

      for (let rowIndex = 7; rowIndex < 7 + personsCount; rowIndex++) {
        const cell = worksheet.getCell(`D${rowIndex}`);
        cell.value = {
          formula: `SUM(E${rowIndex}:${String.fromCharCode(
            68 + columnCount
          )}${rowIndex})`,
        };
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "ffd9d9d9" },
        };
        cell.font = { bold: true };
        cell.alignment = { horizontal: "center" };
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      }

      // Add sum title row at the bottom left
      worksheet.mergeCells(`A${personsCount + 7}:C${personsCount + 7}`);
      const bottomLeftSumCell = worksheet.getCell(`A${personsCount + 7}`);
      bottomLeftSumCell.value = "Summen";
      bottomLeftSumCell.font = { bold: true };
      bottomLeftSumCell.alignment = {
        horizontal: "right",
      };
      bottomLeftSumCell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
      bottomLeftSumCell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "fff2f2f2" },
      };

      // Add sum row at the bottom for all used columns
      for (let colIndex = 4; colIndex <= 4 + columnCount; colIndex++) {
        const columnLetter = String.fromCharCode(64 + colIndex); // 64 is the ASCII code for 'A' - 1
        const cell = worksheet.getCell(`${columnLetter}${personsCount + 7}`);
        cell.value = {
          formula: `SUM(${columnLetter}7:${columnLetter}${personsCount + 6})`,
        };
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "ffd9d9d9" },
        };
        cell.font = { bold: true };
        cell.alignment = { horizontal: "center" };
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      }
    },
    startDownload(buffer) {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      const documentTitle = this.exportConfig.fileTitle + ".xlsx";
      link.download = documentTitle || "Abrechnungsdaten.xlsx";
      link.click();
    },
    hexToARGB(hex) {
      hex = hex.replace(/^#/, "");
      if (hex.length === 6) {
        hex = "FF" + hex;
      }

      let alpha = hex.slice(0, 2);
      let red = hex.slice(2, 4);
      let green = hex.slice(4, 6);
      let blue = hex.slice(6, 8);

      return alpha + red + green + blue;
    },
    getDay(timestamp) {
      const date = timestamp.toDate();
      return date.getDate();
    },
    getTime(timestamp) {
      const date = timestamp.toDate();
      const hours = date.getHours().toString().padStart(2, "0");
      const minutes = date.getMinutes().toString().padStart(2, "0");
      return `${hours}:${minutes}`;
    },
    timestampConverter(
      timestamp,
      options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        // hour: "2-digit",
        // minute: "2-digit",
      }
    ) {
      let date;
      if (
        timestamp.seconds !== undefined &&
        timestamp.nanoseconds !== undefined
      ) {
        // Handle demoData timestamp format
        date = new Date(timestamp.seconds * 1000);
      } else {
        // Handle firebase timestamp
        date = timestamp.toDate();
      }
      return date.toLocaleDateString("de-DE", options);
    },
    getUnitsDisplay(units) {
      return units.map((unit) => unit.title).join(", ") || "";
    },
  },
};
</script>
